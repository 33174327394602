import { FormEvent, useEffect, useState } from "react";
import Card from "../../commmon/Card";
import Form from "../../commmon/Form";
import InputField from "../../fields/InputField";
import FormRow from "../../commmon/FormRow";
import { apiCall } from "../../../functions";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import { useOrgData } from "../../../context/OrgContext";

import { ReactComponent as LeagueLabLogo } from "../../../svgs/vendors/LeagueLab.svg";
import clsx from "clsx";

const LeagueLab = () => {
    const { org } = useOrgData();
    const { setAlertMessage } = useAlert();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [integrationData, setIntegrationData] = useState<LeagueLabIntegrationData | undefined>();
    const [sites, setSites] = useState<any>([])
    const [selectedSite, setSelectedSite] = useState<string>("");

    useEffect(() => {
        if (showDetails) {
            apiCall<Integration>(`/api/integration/leaguelab/info`)
            .then((resp) => {
                if (resp.data.data) {
                    setIntegrationData(JSON.parse(resp.data.data || "") || undefined)
                }
            })
            .catch((err) => {
                console.warn(err);
            });
        }
    }, [showDetails])

    async function onSaveConnectionData(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        if (sites.length === 0) {
            const sites = await (await fetch(`/api/integration/leaguelab/sites`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ clientToken: formData.clientToken })
            })).json();

            setSites(sites);
            return false;
        }
        else {
            if (!formData.clientToken || !selectedSite) {
                setAlertMessage("Unable to save. Please provide all data.", AlertTypeEnum.ERROR)
                return false;
            }

            try {
                const result = await apiCall<Integration>(`/integration/leaguelab/save`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        orgId: org?.id,
                        clientToken: formData["clientToken"],
                        siteToken: selectedSite
                    })
                })

                setAlertMessage("Saved connection information", AlertTypeEnum.SUCCESS);
                setIntegrationData(JSON.parse(result.data.data || ""));
                setShowDetails(false);
            }
            catch (err) {
                console.error(err);
                setAlertMessage("Unable to save connection info", AlertTypeEnum.ERROR);
                return false;
            }
            finally {
                return true;
            }
        }
    }

    function onCancelClicked() {
        setSelectedSite("");
        setShowDetails(false);
        setSites([]);
    }

    return (
        <div className="integration leagueapps">
            <Card className={showDetails ? "display-only" : ""}>
                <Card.Content>
                    <div className="logo" onClick={() => setShowDetails(true)}>
                        <LeagueLabLogo className="leagueapps" />
                    </div>
                    {
                        showDetails ? (
                            <Form 
                                onSubmitClicked={onSaveConnectionData}
                                onCancelClicked={onCancelClicked}
                            >
                                <FormRow className="full">
                                    <InputField type="text" placeholder="Client Token" name="clientToken" defaultValue={integrationData?.clientToken} autoComplete="none" />
                                </FormRow>
                                {
                                    sites.length > 0 && (
                                        <section className="full">
                                            <h4>Select Site</h4>
                                            {
                                                sites.map((site: any, i: number) => (
                                                    <Card 
                                                        key={`site-${i}`} 
                                                        onClick={() => setSelectedSite(site.siteToken)}
                                                        className={clsx(selectedSite === site.siteToken ? "selected" : "")}
                                                    >
                                                        <Card.Content>{site.name}</Card.Content>
                                                    </Card>
                                                ))
                                            }
                                        </section>
                                    )
                                }
                            </Form>
                        ) : null
                    }
                </Card.Content>
            </Card>
        </div>
    )
}

export default LeagueLab;