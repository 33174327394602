import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Card from "../commmon/Card";
import { useOrgData } from "../../context/OrgContext";
import { useAuth } from "../../context/AuthContext";
import { PermissionLevel } from "../../enum/PermissionLevelEnum";

import "../../styles/scss/organization.scss";

const AdminHome = function() {
    const nav = useNavigate();
    const { orgId, seasonId, ...params } = useParams();
    const { org, loadData, getUserPermissionLevel } = useOrgData();
    const { user } = useAuth();
    const [showNav, setShowNav] = useState<boolean>(true);

    useEffect(() => {
        loadData(orgId);
    }, [orgId]);

    useEffect(() => {
        setShowNav(params["*"] && params["*"].split("/").filter(s => s).length > 1 ? false : true);
    }, [params])

    function onCardClicked(name: string) {
        nav(`/org/${orgId}/${name}`)
    }

    return (
        <div id="org-home">
            <h2 className="org-name">
                {
                    org ? (
                        `${org.name} - ${org.location}`
                    ) : (
                        "Select an organization"
                    )
                }
            </h2>
            <div id="org-content">
                {
                    showNav ? (
                        <section className="org-menu">
                            <Card onClick={() => onCardClicked("seasons")}>
                                <Card.Header>
                                    <h3>Seasonal Awards</h3>
                                </Card.Header>
                            </Card>

                            <Card onClick={() => onCardClicked("profile")}>
                                <Card.Header>
                                    <h3>Update profile</h3>
                                </Card.Header>
                            </Card>

                            {
                                getUserPermissionLevel(user) >= PermissionLevel.OWNER || 
                                user?.siteAdmin 
                                ? (
                                    <Card onClick={() => onCardClicked("users")}>
                                        <Card.Header>
                                            <h3>Organization Accounts</h3>
                                        </Card.Header>
                                    </Card>
                                ) : null
                            }

                            {
                                getUserPermissionLevel(user) >= PermissionLevel.OWNER || 
                                user?.siteAdmin ? (
                                    <Card onClick={() => onCardClicked("settings")}>
                                        <Card.Header>
                                            <h3>Organization Settings</h3>
                                        </Card.Header>
                                    </Card>
                                ) : null
                            }
                            
                            {
                                getUserPermissionLevel(user) >= PermissionLevel.OWNER || 
                                user?.siteAdmin ? (
                                    <Card onClick={() => onCardClicked("integrations")}>
                                        <Card.Header>
                                            <h3>3rd Party Integrations</h3>
                                        </Card.Header>
                                    </Card>
                                ) : null
                            }

                            <Card onClick={() => nav("/logout")}>
                                <Card.Header>
                                    <h3>Logout</h3>
                                </Card.Header>
                            </Card>
                        </section>
                    ) : (
                        <Outlet />
                    )
                }
            </div>
        </div>
    )

}

export default AdminHome;